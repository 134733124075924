
import isEmpty from 'lodash/isEmpty'

import { COMPANY_NAME } from '@config/constants'
import { generateAlternateLinks } from '@core/helpers/head'

import {
  DESCRIPTION_TAGS,
  IMAGE_TAGS,
  NOINDEX_META_TAG,
  TITLE_TAGS,
  createMetaForGroupedTags,
} from './helpers/meta'
import DefaultLayout from './layouts/Default'
import { requestCMSPageConfigFromRoute, sanitizeRouteParams } from './service'

export default {
  components: {
    DefaultLayout,
  },

  async asyncData({ route, store, redirect, error }) {
    try {
      const { payload } = await store.dispatch(
        'http/request',
        requestCMSPageConfigFromRoute({ route }),
      )

      const cleanParams = sanitizeRouteParams({
        parentPageInfos: payload.parentPageInfos,
        route,
      })

      if (!isEmpty(cleanParams)) {
        return redirect(301, {
          ...route,
          params: {
            ...route.params,
            ...cleanParams,
          },
        })
      }

      const linksCountries = payload.meta.alternateLinks.map(
        ({ country: linkCountry }) => linkCountry,
      )

      const alternateLinks = await generateAlternateLinks({
        link: route,
        baseUrl: store.getters['config/baseURL'],
        countries: linksCountries,
      })

      return {
        ...payload,
        alternateLinks,
      }
    } catch (err) {
      const statusCode = err.status_code || 404
      const message = err.message || ''

      return error({
        statusCode,
        message,
      })
    }
  },

  data() {
    return {
      alternateLinks: [],
      pageType: '',
      pageName: '',
      pageCategory: '',
      parentPageInfos: {},
      title: '',
      badge: '',
      titleSeo: '',
      titleBreadcrumb: '',
      banner: {},
      meta: {
        title: '',
        description: '',
        alternateLinks: [],
        image: { src: '' },
      },
      textSeo: {},
      blocks: [],
      hideFromSearchEngine: false,
    }
  },

  head() {
    const { titleSeo, meta: cmsMeta } = this
    const { title, description, image } = cmsMeta
    const imageUrl = image.src

    const meta = [
      ...createMetaForGroupedTags([
        {
          tags: DESCRIPTION_TAGS,
          content: description,
        },
        {
          tags: TITLE_TAGS,
          content: title,
        },
        {
          tags: IMAGE_TAGS,
          content: imageUrl,
        },
      ]),
      ...(this.hideFromSearchEngine === true ? [NOINDEX_META_TAG] : []),
    ]

    return {
      title: titleSeo,
      titleTemplate: `%s | ${COMPANY_NAME}`,
      meta,
      link: this.alternateLinks,
    }
  },

  /**
   * Nuxt doesn't merge the component's asyncData with its data after navigating to a child page using the same component.
   * We have to do it ourselves to trigger the re-rendering of the page.
   * see: https://github.com/nuxt/nuxt.js/issues/2591
   */
  watch: {
    $route() {
      Object.assign(this.$data, this.$options.data())
    },
  },
}
